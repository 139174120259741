import React, { useLayoutEffect, useMemo, useState } from 'react';
import { BigNumber } from 'bignumber.js';
import { Box, CircularProgress, Typography } from '@mui/material';
import { Trans } from '@lingui/macro';
import { RangeConfigType } from '@yldr/contract-helpers';
import { nearestUsableTick, TICK_SPACINGS, TickMath } from '@uniswap/v3-sdk';

import { AlertBanner } from '../../../ui-kit/AlertBanner';
import { displayTokenAmount } from '../../../utils/displayTokenAmount';
import { useModalContext } from '../../../hooks/useModal';
import { ToggleButtonGroup } from '../../../ui-kit/ToggleButtonGroup';
import { useAppDataContext } from '../../../hooks/app-data-provider/useAppDataProvider';
import { findTokenInReserves } from '../../../utils/findTokenInReserves';
import { useRebalancePositionPoolData } from '../../../components-yldr/RebalancePosition/hooks/useRebalancePositionPoolData';
import { LiquidityChartRangeInput } from '../../../components-yldr/LiquidityChartRangeInput';
import { getTickToPrice } from '../../../components-yldr/LiquidityChartRangeInput/utils/getTickToPrice';
import { Bound } from '../../../components-yldr/LiquidityChartRangeInput/state/mint/v3/actions';
import { priceToTick } from '../../../utils/priceToTick';
import { useCurrency } from '../../../components-yldr/LiquidityChartRangeInput/hooks/useCurrency';
import { ToggleButton } from '../../../components/primitives/ToggleButton/ToggleButton';
import { ScrollModalContent, ScrollModalFooter, ScrollModalHeader } from '../../../components/primitives/ScrollModal';
import { DetailsNumberLine, TxModalDetails } from '../FlowCommons/TxModalDetails';
import { TxModalTitleModern } from '../FlowCommons/TxModalTitle';
import { PositionModalWrapperProps } from '../FlowCommons/PositionModalWrapper';
import { TxSuccessView } from '../FlowCommons/Success';
import { SLIPPAGE_WARNING } from '../constants';
import { AutoExitOutOfRange } from './AutoExitOutOfRange';
import { AutoExitPrice } from './AutoExitPrice';
import { AutoExitActions, IAutoExitProps } from './AutoExitActions';
import { EGasFeeCeilingType } from './types';
import { AutoExitGasFeeCeiling } from './AutoExitGasFeeCeiling';
import { PROTOCOL_FEE, REBALANCE_TRIGGER_LIST } from './constants';
import { decompileAutoExitData } from './helpers/decompileAutoExitData';
import { detectChangedValues } from './helpers/detectChangedValues';

export const AutoExitModalContent = React.memo(
  ({ enrichedPosition, configuredAutomationsData, positionAddress, isWrongNetwork }: PositionModalWrapperProps) => {
    const [rangeConfigType, setRangeConfigType] = useState(RangeConfigType.RANGE);
    const [gasFeeCeilingType, setGasFeeCeilingType] = useState(EGasFeeCeilingType.percent);
    const [gasFeeCeilingValue, setGasFeeCeilingValue] = useState('');
    const [autoExitInitialized, setAutoExitInitialized] = useState(false);
    const [triggerLower, setTriggerLower] = useState(enrichedPosition.tickLower);
    const [triggerUpper, setTriggerUpper] = useState(enrichedPosition.tickUpper);
    const [isSorted, setSorted] = useState(true);
    const { mainTxState, txError, setTxError, gasLimit } = useModalContext();
    const { reserves } = useAppDataContext();

    const tokenA = findTokenInReserves({ reserves, address: enrichedPosition.token0.underlyingAsset });
    const tokenB = findTokenInReserves({ reserves, address: enrichedPosition.token1.underlyingAsset });

    const baseCurrency = useCurrency(isSorted ? tokenA : tokenB);
    const quoteCurrency = useCurrency(isSorted ? tokenB : tokenA);

    const displayCurrentPrice = isSorted ? enrichedPosition.poolPrice : enrichedPosition.invertedPoolPrice;

    const triggerLowerAllowable = triggerLower > enrichedPosition.tickCurrent
      ? enrichedPosition.tickCurrent
      : triggerLower

    const triggerUpperAllowable = triggerUpper < enrichedPosition.tickCurrent
      ? enrichedPosition.tickCurrent
      : triggerUpper

    const hasValueChanges = detectChangedValues(
      configuredAutomationsData?.deleverages[0],
      {
        triggerLower: triggerLowerAllowable,
        triggerUpper: triggerUpperAllowable,
        gasFeeCeilingType,
        gasFeeCeilingValue,
      },
    );

    const autoExitActionsProps: IAutoExitProps = {
      disabled: !enrichedPosition.positionInRange,
      marketId: enrichedPosition.marketId,
      initialized: autoExitInitialized,
      gasFeeConfig: {
        maxUsd: gasFeeCeilingType === EGasFeeCeilingType.usd
          ? Number(gasFeeCeilingValue)
          : 0,
        maxPositionPercent: gasFeeCeilingType === EGasFeeCeilingType.percent
          ? BigNumber(gasFeeCeilingValue).multipliedBy(100).decimalPlaces(0).toNumber()
          : 0,
      },
      triggerLower: triggerLowerAllowable,
      triggerUpper: triggerUpperAllowable,
      positionAddress,
      isWrongNetwork,
      hasValueChanges,
    };

    const priceLower = getTickToPrice(
      isSorted ? baseCurrency?.wrapped : quoteCurrency?.wrapped,
      isSorted ? quoteCurrency?.wrapped : baseCurrency?.wrapped,
      triggerLower,
    );

    const priceUpper = getTickToPrice(
      isSorted ? baseCurrency?.wrapped : quoteCurrency?.wrapped,
      isSorted ? quoteCurrency?.wrapped : baseCurrency?.wrapped,
      triggerUpper,
    );

    const feeAmount = enrichedPosition.feeAmount;

    const tickSpaceLimits = useMemo(
      () => ({
        [Bound.LOWER]: feeAmount ? nearestUsableTick(TickMath.MIN_TICK, TICK_SPACINGS[feeAmount]) : undefined,
        [Bound.UPPER]: feeAmount ? nearestUsableTick(TickMath.MAX_TICK, TICK_SPACINGS[feeAmount]) : undefined,
      }),
      [feeAmount]
    );

    const ticksAtLimit = useMemo(
      () => ({
        [Bound.LOWER]: feeAmount && triggerLower === tickSpaceLimits.LOWER,
        [Bound.UPPER]: feeAmount && triggerUpper === tickSpaceLimits.UPPER,
      }),
      [tickSpaceLimits, triggerLower, triggerUpper, feeAmount]
    )

    const { data: positionPoolData } = useRebalancePositionPoolData({
      marketId: enrichedPosition.marketId,
      baseCurrency: isSorted ? baseCurrency : quoteCurrency,
      quoteCurrency: isSorted ? quoteCurrency : baseCurrency,
      feeAmount,
    });

    const handleOnCurrencyToggle = () => setSorted((prevState) => !prevState);

    const handleLeftRangeInput = (typedValue: string) => {
      const newLowerTick = priceToTick(
        isSorted
          ? BigNumber(typedValue)
          : enrichedPosition.poolPrice.multipliedBy(
            BigNumber(typedValue).div(enrichedPosition.invertedPoolPrice).decimalPlaces(12)
          ),
        enrichedPosition.token0.decimals,
        enrichedPosition.token1.decimals,
      );
      setTriggerLower(
        newLowerTick > enrichedPosition.tickCurrent
          ? enrichedPosition.tickCurrent
          : newLowerTick
      );
    };

    const handleRightRangeInput = (typedValue: string) => {
      const newUpperTick = priceToTick(
        isSorted
          ? BigNumber(typedValue)
          : enrichedPosition.poolPrice.multipliedBy(
            BigNumber(typedValue).div(enrichedPosition.invertedPoolPrice).decimalPlaces(12)
          ),
        enrichedPosition.token0.decimals,
        enrichedPosition.token1.decimals,
      );
      setTriggerUpper(
        newUpperTick < enrichedPosition.tickCurrent
          ? enrichedPosition.tickCurrent
          : newUpperTick
      );
    };

    useLayoutEffect(() => {
      const autoExitDataSaved = decompileAutoExitData(configuredAutomationsData?.deleverages[0]);
      const gasFeeCeilingTypeSaved = autoExitDataSaved.gasFeeConfig.maxUsd
        ? EGasFeeCeilingType.usd
        : EGasFeeCeilingType.percent;
      if (autoExitDataSaved.initialized) {
        setAutoExitInitialized(autoExitDataSaved.initialized);
        setGasFeeCeilingType(gasFeeCeilingTypeSaved);
        setGasFeeCeilingValue(
          gasFeeCeilingTypeSaved === EGasFeeCeilingType.usd
            ? autoExitDataSaved.gasFeeConfig.maxUsd.toString()
            : autoExitDataSaved.gasFeeConfig.maxPositionPercent.toString()
        );
        setTriggerLower(autoExitDataSaved.triggerLower);
        setTriggerUpper(autoExitDataSaved.triggerUpper);
      }
    }, []);

    if (mainTxState.success) {
      return (
        <ScrollModalContent>
          <TxSuccessView
            action={<Trans>successfully enabled</Trans>}
            amount={mainTxState.value}
            prefix="$"
          />
        </ScrollModalContent>
      );
    }

    return positionPoolData ? (
      <>
        <ScrollModalHeader withDivider={true}>
          <TxModalTitleModern
            title={autoExitInitialized ? 'Disable auto-exit' : 'Enable auto-exit'}
            marketName={enrichedPosition?.marketName}
            tokenA={enrichedPosition?.token0}
            tokenB={enrichedPosition?.token1}
            fee={enrichedPosition?.fee}
          />
        </ScrollModalHeader>

        <ScrollModalContent sx={{ display: 'flex', flexDirection: 'column', gap: 5, pt: 5 }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
            <Typography variant="secondary14" color="text.tertiary">
              Close position when
            </Typography>
            <ToggleButtonGroup
              color="primary"
              exclusive
              value={rangeConfigType}
              onChange={(_event, value) => value && setRangeConfigType(value)}
              sx={{ width: '100%' }}
            >
              {REBALANCE_TRIGGER_LIST.map((item) => (
                <ToggleButton
                  key={item.value}
                  value={item.value}
                  size="small"
                  sx={{
                    flex: '1 1 auto',
                    fontSize: '14px',
                  }}
                >
                  {item.label}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>

          {rangeConfigType === RangeConfigType.RANGE && (
            <AutoExitOutOfRange
              enrichedPosition={enrichedPosition}
              baseCurrency={baseCurrency}
              quoteCurrency={quoteCurrency}
              symbolTokenA={enrichedPosition.token0.symbol}
              symbolTokenB={enrichedPosition.token1.symbol}
              onCurrencyToggle={handleOnCurrencyToggle}
              isSorted={isSorted}
            />
          )}

          {rangeConfigType === RangeConfigType.PRICE && (
            <AutoExitPrice
              enrichedPosition={enrichedPosition}
              symbolTokenA={enrichedPosition.token0.symbol}
              symbolTokenB={enrichedPosition.token1.symbol}
              triggerLower={triggerLower}
              triggerUpper={triggerUpper}
              setTriggerLower={setTriggerLower}
              setTriggerUpper={setTriggerUpper}
              onCurrencyToggle={handleOnCurrencyToggle}
              isSorted={isSorted}
            />
          )}

          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
            <Typography variant="secondary13" color="text.secondary">Current Price</Typography>
            <Box sx={{
              display: 'flex',
              alignItems: 'end',
              gap: 1.5,
              lineHeight: 0,
              ['>p']: {
                lineHeight: '100%',
              }
            }}>
              <Typography variant="secondary16">{displayTokenAmount(displayCurrentPrice)}</Typography>
              <Typography variant="secondary14" color="text.tertiary">
                {baseCurrency?.symbol} per {quoteCurrency?.symbol}
              </Typography>
            </Box>
          </Box>

          <LiquidityChartRangeInput
            key={isSorted ? 'sorted' : 'inverted'}
            positionPoolData={positionPoolData}
            currencyA={baseCurrency}
            currencyB={quoteCurrency}
            ticksAtLimit={ticksAtLimit}
            price={
              isSorted
                ? enrichedPosition.poolPrice.toNumber()
                : enrichedPosition.invertedPoolPrice.toNumber()
            }
            priceLower={priceLower}
            priceUpper={priceUpper}
            onLeftRangeInput={handleLeftRangeInput}
            onRightRangeInput={handleRightRangeInput}
            interactive={rangeConfigType !== RangeConfigType.RANGE}
            sx={{ minHeight: '120px' }}
          />

          <AutoExitGasFeeCeiling
            gasFeeCeilingType={gasFeeCeilingType}
            setGasFeeCeilingType={setGasFeeCeilingType}
            value={gasFeeCeilingValue}
            setValue={setGasFeeCeilingValue}
            marketReferenceCurrencyValue={enrichedPosition.marketReferenceCurrencyValue}
          />

          <TxModalDetails
            description={SLIPPAGE_WARNING}
            gasLimit={gasLimit}
            skipLoad={true}
            sx={{ mt: 0 }}
          >
            <DetailsNumberLine
              description="Protocol fee"
              captionColor="text.secondary"
              value={PROTOCOL_FEE}
              percent={true}
            />
          </TxModalDetails>

          {txError && (
            <AlertBanner variant="error" txError={txError} onClose={() => setTxError(undefined)} />
          )}
        </ScrollModalContent>

        <ScrollModalFooter>
          <AutoExitActions {...autoExitActionsProps} />
        </ScrollModalFooter>
      </>
    ) : (
      <Box sx={{
        minHeight: '400px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
        <CircularProgress />
      </Box>
    );
  }
);
