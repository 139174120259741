import { IRebalanceTriggerItem } from './types';
import { RangeConfigType } from '@yldr/contract-helpers';

export const REBALANCE_TRIGGER_LIST: IRebalanceTriggerItem[] = [
  {
    value: RangeConfigType.RANGE,
    label: 'Out of range'
  },
  {
    value: RangeConfigType.PRICE,
    label: 'Price'
  },
];

export const PROTOCOL_FEE = 0.015;
