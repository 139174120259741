import { ScheduledDeleverageStructOutput } from '@yldr/contract-helpers';
import { EGasFeeCeilingType } from '../types';
import { decompileAutoExitData } from './decompileAutoExitData';

export const detectChangedValues = (
  autoExit: ScheduledDeleverageStructOutput | undefined,
  options: {
    gasFeeCeilingType: EGasFeeCeilingType;
    gasFeeCeilingValue: string;
    triggerLower: number,
    triggerUpper: number,
  },
) => {
  const autoExitDataSaved = decompileAutoExitData(autoExit);

  const gasFeeCeilingTypeSaved = autoExitDataSaved.gasFeeConfig.maxUsd
    ? EGasFeeCeilingType.usd
    : EGasFeeCeilingType.percent;

  if (options.gasFeeCeilingType !== gasFeeCeilingTypeSaved) return true;

  if (gasFeeCeilingTypeSaved === EGasFeeCeilingType.usd) {
    if (options.gasFeeCeilingValue !== autoExitDataSaved.gasFeeConfig.maxUsd.toString()) return true;
  }

  if (gasFeeCeilingTypeSaved === EGasFeeCeilingType.percent) {
    if (options.gasFeeCeilingValue !== autoExitDataSaved.gasFeeConfig.maxPositionPercent.toString()) return true;
  }

  if (options.triggerLower !== autoExitDataSaved.triggerLower) return true;
  if (options.triggerUpper !== autoExitDataSaved.triggerUpper) return true;

  return false;
}
