import { ScheduledDeleverageStructOutput } from '@yldr/contract-helpers';
import { BigNumber } from 'bignumber.js';
import { USD_DECIMALS } from '@yldr/math-utils';

export const decompileAutoExitData = (data?:  ScheduledDeleverageStructOutput): {
  initialized: boolean;
  triggerLower: number;
  triggerUpper: number;
  gasFeeConfig: {
    maxUsd: number;
    maxPositionPercent: number;
  };
} => {
  if (!data) {
    return {
      triggerLower: 0,
      triggerUpper: 0,
      gasFeeConfig: {
        maxUsd: 0,
        maxPositionPercent: 0,
      },
      initialized: false,
    }
  }
  return {
    triggerLower: data.triggerLower,
    triggerUpper: data.triggerUpper,
    gasFeeConfig: {
      maxUsd: BigNumber(data.gasFeeConfig.maxUsd.toString())
        .div(BigNumber(10).pow(USD_DECIMALS))
        .toNumber(),
      maxPositionPercent: data.gasFeeConfig.maxPositionPercent.toNumber() / 100,
    },
    initialized: data.initialized,
  }
};
