import React, { useEffect, useRef } from 'react';
import { BigNumber } from 'bignumber.js';
import { Box, Typography } from '@mui/material';
import { PriceDiffStepCounter } from '../../../ui-kit/PriceDiffStepCounter';
import { CurrencyToggler } from '../../../components-yldr/components/CurrencyToggler';
import { priceToTick } from '../../../utils/priceToTick';
import { IEnrichedUniswapPosition } from '../../../types/uniswapTokens';
import { calculatePositionRange } from '../../../utils/calculatePositionRange';

export interface IAutoRebalancePriceProps {
  enrichedPosition: IEnrichedUniswapPosition;
  symbolTokenA: string;
  symbolTokenB: string;
  onCurrencyToggle: () => void;
  triggerLower: number;
  triggerUpper: number;
  setTriggerLower: React.Dispatch<React.SetStateAction<number>>;
  setTriggerUpper: React.Dispatch<React.SetStateAction<number>>;
  isSorted: boolean;
}

export const AutoExitPrice = ({
  enrichedPosition,
  symbolTokenA,
  symbolTokenB,
  onCurrencyToggle,
  triggerLower,
  triggerUpper,
  setTriggerLower,
  setTriggerUpper,
  isSorted,
}: IAutoRebalancePriceProps) => {
  const [lowerPriceEnabled, setLowerPriceEnabled] = React.useState(true);
  const [upperPriceEnabled, setUpperPriceEnabled] = React.useState(true);
  const [lowerPrice, setLowerPrice] = React.useState('');
  const [upperPrice, setUpperPrice] = React.useState('');
  const isSortedRef = useRef(isSorted);

  const currentPrice = isSorted
    ? enrichedPosition.poolPrice
    : enrichedPosition.invertedPoolPrice;

  const handleOnBlurLowerPrice = () => {
    if (lowerPriceEnabled && lowerPrice) {
      const triggerLower = priceToTick(
        BigNumber(
          isSorted
            ? lowerPrice
            : enrichedPosition.poolPrice.multipliedBy(
              BigNumber(lowerPrice).div(enrichedPosition.invertedPoolPrice).decimalPlaces(12)
            )
        ),
        enrichedPosition.token0.decimals,
        enrichedPosition.token1.decimals,
      );
      setTriggerLower(triggerLower);
    }
  };

  const handleOnBlurUpperPrice = () => {
    if (upperPriceEnabled && upperPrice) {
      const triggerUpper = priceToTick(
        BigNumber(
          isSorted
            ? upperPrice
            : enrichedPosition.poolPrice.multipliedBy(
              BigNumber(upperPrice).div(enrichedPosition.invertedPoolPrice).decimalPlaces(12)
            )
        ),
        enrichedPosition.token0.decimals,
        enrichedPosition.token1.decimals,
      );
      setTriggerUpper(triggerUpper);
    }
  };

  useEffect(() => {
    const lowerPriceCalculated = calculatePositionRange(
      triggerLower,
      enrichedPosition.token0.decimals,
      enrichedPosition.token1.decimals,
    );
    setLowerPrice(
      isSorted
        ? lowerPriceCalculated.decimalPlaces(12).toFixed()
        : enrichedPosition.invertedPoolPrice.multipliedBy(
          BigNumber(lowerPriceCalculated).div(enrichedPosition.poolPrice)
        ).decimalPlaces(12).toFixed()
    );
  }, [triggerLower]);

  useEffect(() => {
    const upperPriceCalculated = calculatePositionRange(
      triggerUpper,
      enrichedPosition.token0.decimals,
      enrichedPosition.token1.decimals,
    );
    setUpperPrice(
      isSorted
        ? upperPriceCalculated.decimalPlaces(12).toFixed()
        : enrichedPosition.invertedPoolPrice.multipliedBy(
          BigNumber(upperPriceCalculated).div(enrichedPosition.poolPrice)
        ).decimalPlaces(12).toFixed()
    );
  }, [triggerUpper]);

  useEffect(() => {
    if (!lowerPriceEnabled) {
      setTriggerLower(enrichedPosition.tickLower);
    }
  }, [lowerPriceEnabled]);

  useEffect(() => {
    if (!upperPriceEnabled) {
      setTriggerUpper(enrichedPosition.tickUpper);
    }
  }, [upperPriceEnabled]);

  useEffect(() => {
    if (isSorted !== isSortedRef.current) {
      const prevPrice = isSortedRef.current
        ? enrichedPosition.poolPrice
        : enrichedPosition.invertedPoolPrice;
      const lowerPriceRate = BigNumber(lowerPrice).div(prevPrice);
      const upperPriceRate = BigNumber(upperPrice).div(prevPrice);
      setLowerPrice(
        currentPrice.multipliedBy(lowerPriceRate).decimalPlaces(12).toFixed()
      );
      setUpperPrice(
        currentPrice.multipliedBy(upperPriceRate).decimalPlaces(12).toFixed()
      );
      isSortedRef.current = isSorted;
    }
  }, [isSorted]);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 4, mb: 1 }}>
        <Typography variant="secondary14" color="text.tertiary">
          Select price range
        </Typography>
        <CurrencyToggler
          baseCurrencySymbol={isSorted ? symbolTokenA : symbolTokenB}
          quoteCurrencySymbol={isSorted ? symbolTokenB : symbolTokenA}
          isSorted={isSorted}
          onCurrencyToggle={onCurrencyToggle}
          sx={{ flex: 'unset', width: 'unset' }}
        />
      </Box>
      <PriceDiffStepCounter
        title="Min price"
        symbolTokenA={isSorted ? symbolTokenA : symbolTokenB}
        symbolTokenB={isSorted ? symbolTokenB : symbolTokenA}
        value={lowerPrice}
        minValue={
          currentPrice
            .minus(currentPrice.div(10000))
            .multipliedBy(-1)
            .decimalPlaces(12)
            .toNumber()
          }
        maxValue={currentPrice.toNumber()}
        enabled={lowerPriceEnabled}
        setEnabled={setLowerPriceEnabled}
        onChange={setLowerPrice}
        onBlur={handleOnBlurLowerPrice}
        onIncrement={() => setTriggerLower(
          triggerLower < enrichedPosition.tickCurrent
            ? triggerLower + 1
            : enrichedPosition.tickCurrent
        )}
        onDecrement={() => setTriggerLower(triggerLower - 1)}
      />
      <PriceDiffStepCounter
        title="Max price"
        symbolTokenA={isSorted ? symbolTokenA : symbolTokenB}
        symbolTokenB={isSorted ? symbolTokenB : symbolTokenA}
        value={upperPrice}
        minValue={currentPrice.toNumber()}
        enabled={upperPriceEnabled}
        setEnabled={setUpperPriceEnabled}
        onChange={setUpperPrice}
        onBlur={handleOnBlurUpperPrice}
        onIncrement={() => setTriggerUpper(triggerUpper + 1)}
        onDecrement={() => setTriggerUpper(
          triggerUpper > enrichedPosition.tickCurrent
            ? triggerUpper - 1
            : enrichedPosition.tickCurrent
        )}
      />
    </Box>
  );
};
